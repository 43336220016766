import React from "react"

import { useImage } from "../../../hooks/useImage"

export const withInvalid = Component => ({ name = "Invalid", page }) => {
  const { getFluidImage } = useImage()

  const { title, description, image: originalImage } = page

  const image = getFluidImage(originalImage)

  Component.displayName = name
  return <Component title={title} image={image} description={description} />
}
